:root {
  /* Dark Theme Colors */
  --bg-primary: #1a1a1a;
  --bg-secondary: #2d2d2d;
  --bg-hover: #3d3d3d;
  --text-primary: #ffffff;
  --text-secondary: #a0a0a0;
  --accent: #3699FF;
  --accent-hover: #187DE4;
  --border-color: #404040;
  --success: #0BB783;
  --warning: #FFA800;
  --danger: #F64E60;
  --info: #8950FC;
  --accent-blue: #228be6;
  --accent-blue-hover: #1c7ed6;
  --accent-blue-light: rgba(34, 139, 230, 0.1);
}

/* Reset all default margins and padding */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Base layout */
body {
  margin: 0;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

.app-container {
  display: flex;
  min-height: 100vh;
  background-color: var(--bg-primary);
  width: 100%;
}

/* Sidebar */
.sidebar {
  width: 250px;
  background-color: var(--bg-secondary);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-right: 1px solid var(--border-color);
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
}

/* Main content */
.main-content {
  margin-left: 250px; /* Match sidebar width */
  flex: 1;
  min-width: 0;
}

.page-header {
  padding: 1.5rem;
  border-bottom: 1px solid var(--border-color);
  background-color: var(--bg-secondary);
}

.dashboard-grid {
  padding: 1.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
}

.stat-card {
  background-color: var(--bg-secondary);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--border-color);
}

.stat-card-content {
  padding: 1.5rem;
}

.stat-header {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1rem;
}

.stat-header h2 {
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-secondary);
  margin: 0;
}

.stat-body {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.stat-number {
  font-size: 2rem;
  font-weight: 700;
  color: var(--text-primary);
}

.stat-trend {
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  width: fit-content;
}

.stat-trend.positive {
  color: #40c057;
  background-color: rgba(64, 192, 87, 0.1);
}

.stat-trend.negative {
  color: #fa5252;
  background-color: rgba(250, 82, 82, 0.1);
}

.stat-trend.neutral {
  color: #fab005;
  background-color: rgba(250, 176, 5, 0.1);
}

/* Update icon sizes */
.nav-icon {
  width: 1.25rem;  /* Reduced from 1.5rem */
  height: 1.25rem;
  flex-shrink: 0;
}

.card-icon {
  width: 1.25rem;  /* Reduced from 1.75rem */
  height: 1.25rem;
  flex-shrink: 0;
}

.nav-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 1rem;
  color: var(--text-secondary);
  text-decoration: none;
  border-radius: 6px;
  transition: all 0.2s;
  font-size: 0.9rem;  /* Slightly smaller text */
}

/* Add chart styles */
.charts-grid {
  padding: 1.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
}

.chart-card {
  background-color: var(--bg-secondary);
  border-radius: 8px;
  padding: 1.5rem;
  border: 1px solid var(--border-color);
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.chart-header h2 {
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-secondary);
  margin: 0;
}

.total-count {
  font-size: 0.875rem;
  color: var(--text-secondary);
}

.chart-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.chart-row {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.chart-label {
  min-width: 100px;
  font-size: 0.875rem;
  color: var(--text-secondary);
}

.chart-bar-container {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.chart-bar {
  height: 8px;
  border-radius: 4px;
  transition: width 0.3s ease;
}

.chart-value {
  font-size: 0.875rem;
  color: var(--text-primary);
  min-width: 2rem;
}

/* Update incident section styles */
.incident-section {
  padding: 1.5rem;
  margin-bottom: 1rem;
  background-color: var(--bg-secondary);
  border-radius: 8px;
  border: 1px solid var(--border-color);
}

.incident-header {
  margin-bottom: 1.5rem;
}

.incident-header h2 {
  font-size: 1.25rem;
  color: var(--text-primary);
  margin: 0;
}

.charts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
}

/* Update spacing styles */
.incidents-breakdown {
  margin-top: 2rem;
  padding: 0 1.5rem;
}

.section-title {
  font-size: 1.25rem;
  color: var(--text-primary);
  margin-bottom: 1.5rem;
}

.incident-section {
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  background-color: var(--bg-secondary);
  border-radius: 8px;
  border: 1px solid var(--border-color);
}

/* Add events table styles */
.events-table-container {
  margin-top: 2rem;
}

.events-table-container h3 {
  font-size: 1rem;
  color: var(--text-primary);
  margin-bottom: 1rem;
}

.events-table {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--bg-primary);
  border-radius: 8px;
  overflow: hidden;
}

.events-table th,
.events-table td {
  padding: 0.75rem 1rem;
  text-align: left;
  border-bottom: 1px solid var(--border-color);
}

.events-table th {
  background-color: var(--bg-primary);
  color: var(--text-secondary);
  font-weight: 600;
  font-size: 0.875rem;
}

.events-table td {
  font-size: 0.875rem;
  color: var(--text-primary);
}

.event-type {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: 500;
}

.event-type.dispatch {
  background-color: rgba(250, 176, 5, 0.1);
  color: #fab005;
}

.event-type.transport {
  background-color: rgba(64, 192, 87, 0.1);
  color: #40c057;
}

.event-type.on {
  background-color: rgba(34, 139, 230, 0.1);
  color: #228be6;
}

.event-type.patient {
  background-color: rgba(190, 75, 219, 0.1);
  color: #be4bdb;
}

.event-type.triage {
  background-color: rgba(250, 82, 82, 0.1);
  color: #fa5252;
}

/* Resources page styles */
.resources {
  flex: 1;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  padding: 1.5rem;
}

.resources-grid {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1.5rem;
}

.resource-card {
  background-color: var(--bg-secondary);
  border-radius: 8px;
  padding: 1.5rem;
  border: 1px solid var(--border-color);
}

.resource-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.resource-title {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.resource-title h3 {
  font-size: 1.25rem;
  margin: 0;
  color: var(--text-primary);
}

.resource-icon {
  width: 1.5rem;
  height: 1.5rem;
  color: var(--text-secondary);
}

.resource-status {
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.875rem;
  font-weight: 500;
}

.resource-details {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--border-color);
}

.detail-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-secondary);
  font-size: 0.875rem;
}

.detail-icon {
  width: 1rem;
  height: 1rem;
}

.resource-events h4 {
  font-size: 0.875rem;
  color: var(--text-secondary);
  margin: 0 0 1rem 0;
}

.events-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.event-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 0.875rem;
}

.event-time {
  color: var(--text-secondary);
  min-width: 4rem;
}

.event-description {
  color: var(--text-primary);
}

/* Add Resource Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.modal-content {
  background-color: #1a1b1e;
  border-radius: 12px;
  padding: 2rem;
  width: 100%;
  max-width: 500px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.modal-header h2 {
  margin: 0;
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
}

.modal-form-group {
  margin-bottom: 1.5rem;
}

.modal-form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
  font-weight: 500;
}

.modal-form-group input,
.modal-form-group select {
  width: 100%;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.03);
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.modal-form-group input:focus,
.modal-form-group select:focus {
  outline: none;
  border-color: #228be6;
  box-shadow: 0 0 0 1px #228be6;
}

.modal-form-group input::placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.modal-button {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.modal-button.cancel {
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
}

.modal-button.cancel:hover {
  background-color: rgba(255, 255, 255, 0.08);
}

.modal-button.submit {
  background-color: #228be6;
  border: none;
  color: white;
}

.modal-button.submit:hover {
  background-color: #1c7ed6;
}

.modal-close {
  padding: 0.5rem;
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: color 0.2s ease;
}

.modal-close:hover {
  color: rgba(255, 255, 255, 0.8);
}

/* Update page header for button */
.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Resources table styles */
.table-container {
  margin: 1.5rem;
  background-color: var(--bg-secondary);
  border-radius: 8px;
  border: 1px solid var(--border-color);
  overflow: hidden;
}

.resources-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.875rem;
}

.resources-table thead {
  background-color: var(--bg-primary);
}

.resources-table th {
  text-align: left;
  padding: 1rem;
  color: var(--text-secondary);
  font-weight: 600;
  border-bottom: 1px solid var(--border-color);
}

.resources-table td {
  padding: 1rem;
  color: var(--text-primary);
  border-bottom: 1px solid var(--border-color);
  background-color: var(--bg-secondary);
}

.resources-table tbody tr:hover {
  background-color: var(--bg-hover);
}

.resources-table tr:last-child td {
  border-bottom: none;
}

.table-icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  color: var(--text-secondary);
  vertical-align: middle;
}

.unit-cell, .crew-cell, .location-cell {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.status-badge {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.75rem;
  font-weight: 500;
}

/* Incidents table styles */
.incidents-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.875rem;
}

.incidents-table thead {
  background-color: var(--bg-primary);
}

.incidents-table th {
  text-align: left;
  padding: 1rem;
  color: var(--text-primary);
  font-weight: 700;
  border-bottom: 1px solid var(--border-color);
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.05em;
}

.incidents-table td {
  padding: 1rem;
  color: var(--text-primary);
  border-bottom: 1px solid var(--border-color);
  background-color: var(--bg-secondary);
}

.incidents-table tbody tr:hover {
  background-color: var(--bg-hover);
}

.incident-id {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.id-name-group {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.incident-number {
  font-weight: 600;
  color: var(--text-primary);
}

.incident-name {
  font-size: 0.75rem;
  color: var(--text-secondary);
}

.priority-badge,
.status-badge {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.75rem;
  font-weight: 500;
}

.time-cell {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.time-info {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.duration {
  font-size: 0.75rem;
  color: var(--text-secondary);
}

.units-cell {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.unit-badge {
  background-color: var(--bg-primary);
  color: var(--text-secondary);
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  border: 1px solid var(--border-color);
}

.patients-cell {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.location-cell {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Add button styles */
.add-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background-color: #228be6;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.add-button:hover {
  background-color: #1c7ed6;
}

.view-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  color: var(--text-primary);
  font-size: 0.75rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.view-button:hover {
  background-color: var(--bg-hover);
  border-color: var(--text-secondary);
}

.btn-icon {
  width: 1rem;
  height: 1rem;
}

/* Update page header for button alignment */
.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
}

.page-header h1 {
  margin: 0;
}

/* Keep other existing styles */

/* Update modal styles for incidents */
.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.modal-form textarea {
  width: 100%;
  padding: 0.75rem;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background-color: var(--bg-primary);
  color: var(--text-primary);
  font-size: 0.875rem;
  resize: vertical;
  min-height: 100px;
}

.modal-form textarea:focus {
  outline: none;
  border-color: #228be6;
}

.modal-header .close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  border-radius: 4px;
}

.modal-header .close-btn:hover {
  background-color: var(--bg-hover);
  color: var(--text-primary);
}

/* Keep existing modal and form styles */

/* Add clickable styles */
.clickable {
  cursor: pointer;
  transition: color 0.2s;
}

.clickable:hover {
  color: #228be6;
}

.clickable:hover .incident-number {
  color: #228be6;
}

.clickable:hover .table-icon {
  color: #228be6;
}

/* Update incident-id styles */
.incident-id {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.25rem;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.incident-id:hover {
  background-color: var(--bg-hover);
}

/* Keep existing styles */

/* Incident Details Styles */
.incident-details {
  padding: 1.5rem;
}

.back-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: none;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  color: var(--text-primary);
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;
}

.back-button:hover {
  background-color: var(--bg-hover);
  border-color: var(--text-secondary);
}

.incident-header {
  margin: 2rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.incident-title {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.header-icon {
  width: 2rem;
  height: 2rem;
  color: var(--text-secondary);
}

.incident-title h1 {
  margin: 0;
  font-size: 1.5rem;
  color: var(--text-primary);
}

.incident-title h2 {
  margin: 0.25rem 0 0 0;
  font-size: 1rem;
  color: var(--text-secondary);
  font-weight: normal;
}

.status-badge.large {
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
}

.details-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.detail-card {
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 1.5rem;
}

.detail-card h3 {
  margin: 0 0 1rem 0;
  font-size: 0.875rem;
  color: var(--text-secondary);
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.detail-content {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: var(--text-primary);
}

.detail-icon {
  width: 1.25rem;
  height: 1.25rem;
  color: var(--text-secondary);
}

.detail-content.times {
  align-items: flex-start;
}

.time-row {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  margin-bottom: 0.5rem;
  color: var(--text-primary);
}

.time-row span:first-child {
  color: var(--text-secondary);
}

.details-section {
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  margin-bottom: 1.5rem;
  overflow: hidden;
}

.section-header {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid var(--border-color);
}

.section-header h3 {
  margin: 0;
  font-size: 0.875rem;
  color: var(--text-secondary);
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.units-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 1rem;
  padding: 1.5rem;
}

.unit-card {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem;
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  color: var(--text-primary);
}

.unit-icon {
  width: 1.25rem;
  height: 1.25rem;
  color: var(--text-secondary);
}

.timeline {
  padding: 1.5rem;
}

.timeline-item {
  display: flex;
  gap: 1.5rem;
  padding: 1rem 0;
  border-left: 2px solid var(--border-color);
  padding-left: 1.5rem;
  position: relative;
}

.timeline-item::before {
  content: '';
  position: absolute;
  left: -5px;
  top: 1.5rem;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--border-color);
}

.timeline-time {
  min-width: 4rem;
  color: var(--text-secondary);
  font-size: 0.875rem;
}

.timeline-content {
  flex: 1;
}

.timeline-event {
  color: var(--text-primary);
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.timeline-details {
  color: var(--text-secondary);
  font-size: 0.875rem;
}

/* Keep existing styles */

/* Add edit mode styles */
.edit-actions {
  display: flex;
  gap: 1rem;
}

.edit-button,
.save-button,
.cancel-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;
}

.edit-button {
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  color: var(--text-primary);
}

.save-button {
  background-color: #40c057;
  border: none;
  color: white;
}

.cancel-button {
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  color: var(--text-secondary);
}

.edit-button:hover {
  background-color: var(--bg-hover);
}

.save-button:hover {
  background-color: #37b24d;
}

.cancel-button:hover {
  background-color: var(--bg-hover);
}

.edit-input,
.edit-select,
.status-select {
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background-color: var(--bg-primary);
  color: var(--text-primary);
  font-size: 0.875rem;
  width: 100%;
}

.edit-input.time {
  width: auto;
}

.edit-input:focus,
.edit-select:focus,
.status-select:focus {
  outline: none;
  border-color: #228be6;
}

.edit-title {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.edit-title .edit-input:first-child {
  font-size: 1.25rem;
  font-weight: 600;
}

.status-select {
  padding: 0.5rem 1rem;
  font-weight: 500;
}

/* Keep existing styles */

/* Incident Details Layout */
.incident-layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  padding: 1.5rem;
  max-width: 2400px;
  margin: 0 auto;
}

.incident-left-column,
.incident-right-column {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  min-width: 0;
}

/* Adjust grid for smaller screens */
@media (max-width: 1200px) {
  .incident-layout {
    grid-template-columns: 1fr;
  }
}

/* Keep all other existing styles */

/* Form Styles */
.patient-form {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--text-secondary);
}

.form-group input,
.form-group select,
.form-group textarea {
  padding: 0.75rem;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  font-size: 0.875rem;
  transition: all 0.2s;
}

.form-group input:disabled {
  background-color: var(--bg-hover);
  cursor: not-allowed;
  color: var(--text-secondary);
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #228be6;
  box-shadow: 0 0 0 1px #228be6;
}

.form-group textarea {
  resize: vertical;
  min-height: 80px;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 0.5rem;
}

.submit-button,
.cancel-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.submit-button {
  background-color: var(--accent-blue);
  border: none;
  color: white;
}

.submit-button:hover {
  background-color: var(--accent-blue-hover);
}

.cancel-button {
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  color: var(--text-primary);
}

.cancel-button:hover {
  background-color: var(--bg-hover);
  border-color: var(--text-secondary);
}

/* Keep existing styles */

/* Update button styling */
.submit-button {
  background-color: #228be6;
}

.submit-button:hover {
  background-color: #1c7ed6;
}

/* Add blue accents to interactive elements */
.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #228be6;
  box-shadow: 0 0 0 1px #228be6;
}

.resource-item:hover,
.log-entry:hover {
  border-left: 3px solid #228be6;
  padding-left: calc(1rem - 3px);
}

.incident-title h1 {
  color: #228be6;
}

.card-header:hover h2 {
  color: #228be6;
}

.resource-details .unit {
  color: #228be6;
}

.log-time {
  color: #228be6;
}

.overview-item .with-icon .item-icon {
  color: #228be6;
}

.total-patients {
  color: #228be6;
  font-weight: 500;
}

.hospital-item:hover {
  background-color: rgba(34, 139, 230, 0.1);
  border-left: 3px solid #228be6;
  padding-left: calc(1rem - 3px);
}

.disposition-item:hover {
  background-color: rgba(34, 139, 230, 0.1);
}

/* Keep other existing styles */

/* Progress bar styles */
.progress-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.progress-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.progress-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
}

.progress-numbers {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.progress-numbers .count {
  color: var(--text-primary);
  font-weight: 500;
}

.progress-numbers .percentage {
  color: var(--text-secondary);
  font-size: 0.75rem;
}

.progress-bar-bg {
  height: 8px;
  background-color: var(--bg-secondary);
  border-radius: 4px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  border-radius: 4px;
  transition: width 0.3s ease;
}

/* Progress bar colors */
.progress-bar.red {
  background-color: #fa5252;
}

.progress-bar.yellow {
  background-color: #fab005;
}

.progress-bar.green {
  background-color: #40c057;
}

.progress-bar.black {
  background-color: #495057;
}

.progress-bar.blue {
  background-color: #228be6;
}

/* Update stats section spacing */
.stats-section {
  padding: 1.5rem;
  background-color: var(--bg-secondary);
  border-radius: 8px;
}

.stats-section h3 {
  margin-bottom: 1.5rem;
}

.total-patients {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--border-color);
  text-align: right;
  color: var(--text-primary);
  font-weight: 500;
}

/* Keep other existing styles */

/* Event Log Table Styles */
.table-container {
  overflow-x: auto;
}

.event-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.875rem;
}

.event-table thead {
  background-color: var(--bg-secondary);
}

.event-table th {
  text-align: left;
  padding: 1rem;
  color: var(--text-secondary);
  font-weight: 500;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.05em;
  border-bottom: 1px solid var(--border-color);
}

.event-table td {
  padding: 1rem;
  border-bottom: 1px solid var(--border-color);
  color: var(--text-primary);
}

.event-table tbody tr:hover {
  background-color: var(--bg-hover);
}

.time-cell {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #228be6;
}

.event-type {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

/* Update the detail-card padding for tables */
.event-log.detail-card {
  padding: 0;
}

.event-log.detail-card h2 {
  padding: 1.5rem;
  margin: 0;
  border-bottom: none;
}

/* Keep other existing styles */

/* Event Log Header Styles */
.event-log-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
}

.event-log-controls {
  display: flex;
  gap: 0.75rem;
}

.filter-button,
.sort-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  font-size: 0.75rem;
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  color: var(--text-secondary);
  cursor: pointer;
  transition: all 0.2s;
}

.filter-button:hover,
.sort-button:hover {
  background-color: var(--bg-hover);
  border-color: var(--text-secondary);
}

.filter-button.active {
  background-color: #228be6;
  border-color: #228be6;
  color: white;
}

/* Significant Column Styles */
.significant-column {
  width: 40px;
  text-align: center;
}

.significant-button {
  background: none;
  border: none;
  padding: 0.25rem;
  cursor: pointer;
  color: var(--text-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
}

.star-icon {
  transition: all 0.2s;
}

.star-icon.filled {
  color: #fab005;
}

.significant-button:hover .star-icon:not(.filled) {
  color: #fab005;
  transform: scale(1.1);
}

/* Significant Row Styles */
.event-table tr.significant {
  background-color: rgba(250, 176, 5, 0.1);
}

.event-table tr.significant:hover {
  background-color: rgba(250, 176, 5, 0.15);
}

/* Update existing table styles */
.event-table th:first-child,
.event-table td:first-child {
  padding: 0.5rem;
}

/* Keep other existing styles */

/* Update event log header styles */
.event-log-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
}

/* Remove the border from the header */
.event-log.detail-card h2 {
  margin: 0;
  border-bottom: none;
}

/* Add styles for user initials */
.user-initials {
  color: var(--text-secondary);
  font-size: 0.875rem;
  text-align: right;
  padding-right: 1.5rem !important;
}

/* Keep other existing styles */

/* Event Entry Form Styles */
.event-entry-form {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid var(--border-color);
}

.event-entry-form form {
  width: 100%;
}

.form-content {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.event-input {
  width: 100%;
  padding: 0.75rem;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  font-size: 0.875rem;
  transition: all 0.2s;
}

.event-input:focus {
  outline: none;
  border-color: #228be6;
  box-shadow: 0 0 0 1px #228be6;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.significant-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.significant-checkbox {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.checkbox-text {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 4px;
  color: var(--text-secondary);
  transition: all 0.2s;
}

.significant-checkbox:checked + .checkbox-text {
  color: #fab005;
}

.significant-checkbox:focus + .checkbox-text {
  outline: 2px solid #228be6;
  outline-offset: 2px;
}

.submit-button {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: #228be6;
  color: white;
  border: none;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;
}

.submit-button:hover:not(:disabled) {
  background-color: #1c7ed6;
}

.submit-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Keep other existing styles */

/* Incident Overview Styles */
.incident-overview {
  background-color: var(--bg-primary);
}

.overview-content {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.overview-item {
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  padding: 0.75rem;
  border-radius: 6px;
  background-color: var(--bg-secondary);
  border-left: 3px solid transparent;
  transition: all 0.2s;
}

.overview-item:hover {
  border-left-color: #228be6;
  background-color: var(--bg-hover);
}

.overview-item label {
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: var(--text-secondary);
  font-weight: 500;
}

.overview-item span {
  color: var(--text-primary);
  font-weight: 500;
  font-size: 1rem;
}

/* Specific field styles */
.overview-item .incident-number {
  font-family: monospace;
  font-size: 1.125rem;
  color: #228be6;
  font-weight: 600;
}

.overview-item .incident-name {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--text-primary);
}

.overview-item .location {
  color: #40c057;
  font-weight: 500;
}

.overview-item .incident-type {
  color: #fab005;
  font-weight: 500;
}

.overview-item .commander {
  color: #e64980;
  font-weight: 500;
}

.with-icon {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.with-icon .item-icon {
  color: var(--text-secondary);
}

/* Add subtle hover effect */
.overview-item:hover .item-icon {
  color: #228be6;
}

.overview-item:hover label {
  color: #228be6;
}

/* Keep other existing styles */

/* Resource Cards Styles */
.resources-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  margin-top: 1.5rem;
}

.resource-card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.resource-form {
  padding: 1rem;
  background-color: var(--bg-secondary);
  border-radius: 6px;
}

.resource-form .form-row {
  display: grid;
  grid-template-columns: 1.5fr 1fr auto;
  gap: 0.75rem;
  align-items: center;
}

.resource-form input,
.resource-form select {
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background-color: var(--bg-primary);
  color: var(--text-primary);
  font-size: 0.875rem;
}

.resource-form input:focus,
.resource-form select:focus {
  outline: none;
  border-color: #228be6;
  box-shadow: 0 0 0 1px #228be6;
}

.add-resource-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #228be6;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 0.875rem;
  cursor: pointer;
}

.add-resource-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.resource-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.resource-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  background-color: var(--bg-secondary);
  border-radius: 4px;
  border-left: 3px solid transparent;
}

.resource-item:hover {
  border-left-color: #228be6;
  background-color: var(--bg-hover);
}

.resource-info {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.resource-unit {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
  color: #228be6;
}

.resource-status {
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  background-color: var(--bg-primary);
  color: var(--text-secondary);
}

.resource-actions {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.resource-time {
  font-size: 0.875rem;
  color: var(--text-secondary);
}

.assign-btn,
.remove-btn {
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  cursor: pointer;
  border: 1px solid var(--border-color);
}

.assign-btn {
  background-color: #40c057;
  color: white;
  border: none;
}

.patient-id-display {
  padding: 0.5rem 0.75rem;
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  color: var(--text-secondary);
  font-family: monospace;
  font-size: 1rem;
}

/* Update card header styles */
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  cursor: default;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.patient-tracking-btn {
  padding: 0.5rem 1rem;
  background-color: #228be6;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;
}

.patient-tracking-btn:hover {
  background-color: #1c7ed6;
}

/* Keep other existing styles */

/* Patient Tracking Styles */
.page-container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.page-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.back-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: none;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  color: var(--text-secondary);
  cursor: pointer;
  transition: all 0.2s;
}

.back-button:hover {
  background-color: var(--bg-secondary);
  border-color: var(--text-secondary);
}

.patient-list {
  padding: 1rem;
}

.empty-state {
  text-align: center;
  padding: 2rem;
  color: var(--text-secondary);
  font-style: italic;
}

/* Keep other existing styles */

/* Patient Table Styles */
.patient-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 0.875rem;
}

.patient-table th {
  position: sticky;
  top: 0;
  background-color: var(--bg-primary);
  text-align: left;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid var(--border-color);
  color: var(--text-secondary);
  font-weight: 500;
  z-index: 1;
}

.patient-table td {
  padding: 0.75rem 1rem;
  border-bottom: 1px solid var(--border-color);
  background-color: var(--bg-secondary);
}

.patient-row {
  transition: all 0.2s;
}

.patient-row:hover td {
  background-color: var(--bg-hover);
}

.patient-list {
  margin: 0 -1.5rem;
  overflow-x: auto;
}

.patient-table tbody tr:last-child td {
  border-bottom: none;
}

/* Keep other existing styles */

/* Update table header styles */
.patient-table th.bold-header {
  font-weight: 600;
  color: var(--text-primary);
}

/* Add filter styles */
.filter-controls {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.filter-group {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: var(--text-secondary);
}

.filter-select {
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;
}

.filter-select:hover {
  border-color: var(--text-secondary);
}

.filter-select:focus {
  outline: none;
  border-color: #228be6;
  box-shadow: 0 0 0 1px #228be6;
}

/* Update card header for filters */
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  border-bottom: 1px solid var(--border-color);
}

/* Keep other existing styles */

/* Action Buttons */
.action-buttons {
  display: flex;
  gap: 0.5rem;
}

.edit-btn,
.delete-btn {
  padding: 0.25rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-secondary);
}

.edit-btn {
  color: var(--text-primary);
}

.delete-btn {
  color: var(--text-primary);
}

.edit-btn:hover {
  background-color: #e9ecef;
  color: #228be6;
}

.delete-btn:hover {
  background-color: #e9ecef;
  color: #fa5252;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #1a1b1e;
  border-radius: 12px;
  padding: 1.5rem;
  width: 100%;
  max-width: 500px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.modal-header {
  margin-bottom: 1.5rem;
}

.modal-header h2 {
  margin: 0;
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.9);
}

.close-button {
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.close-button:hover {
  background-color: var(--bg-hover);
}

.modal-form {
  padding: 1.5rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--text-secondary);
  font-size: 0.875rem;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 0.75rem;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background-color: var(--bg-primary);
  color: var(--text-primary);
  font-size: 0.875rem;
}

.form-group input:focus,
.form-group select:focus {
  outline: none;
  border-color: #228be6;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.save-btn,
.cancel-btn {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;
}

.save-btn {
  background-color: #228be6;
  color: white;
  border: none;
}

.save-btn:hover {
  background-color: #1c7ed6;
}

.cancel-btn {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
}

.cancel-btn:hover {
  background-color: var(--bg-hover);
}

/* Keep other existing styles */

/* Add styles for new form fields */
.patient-notes {
  width: 100%;
  min-height: 100px;
  padding: 0.75rem;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  font-size: 0.875rem;
  font-family: inherit;
  resize: vertical;
}

.patient-notes:focus {
  outline: none;
  border-color: #228be6;
  box-shadow: 0 0 0 1px #228be6;
}

.form-group textarea::placeholder,
.form-group input::placeholder {
  color: var(--text-secondary);
  opacity: 0.7;
}

/* Update modal content for longer form */
.modal-content {
  background-color: #1a1b1e;
  border-radius: 12px;
  padding: 1.5rem;
  width: 100%;
  max-width: 500px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.edit-patient-form {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

/* Keep other existing styles */

/* Add time display styles */
.time-display {
  padding: 0.5rem 0.75rem;
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  color: var(--text-secondary);
  font-family: monospace;
  font-size: 1rem;
}

/* Keep other existing styles */

/* Update time input styles */
.time-input {
  padding: 0.5rem 0.75rem;
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  color: var(--text-primary);
  font-family: monospace;
  font-size: 1rem;
  width: auto;
}

.time-input:focus {
  outline: none;
  border-color: #228be6;
  box-shadow: 0 0 0 1px #228be6;
}

/* Add Change History styles */
.change-history {
  margin-top: 2rem;
  padding-top: 1.5rem;
  border-top: 1px solid var(--border-color);
}

.change-history h4 {
  margin: 0 0 1rem 0;
  color: var(--text-secondary);
  font-size: 0.875rem;
  font-weight: 500;
}

.changes-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  max-height: 200px;
  overflow-y: auto;
  padding-right: 0.5rem;
}

.change-entry {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  background-color: var(--bg-secondary);
  border-radius: 4px;
  font-size: 0.875rem;
}

.change-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.change-field {
  color: var(--text-secondary);
}

.change-arrow {
  color: var(--text-secondary);
  font-size: 0.75rem;
}

.change-value {
  color: var(--text-primary);
  font-weight: 500;
}

.change-meta {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--text-secondary);
  font-size: 0.75rem;
}

.change-user {
  font-weight: 500;
}

.no-changes {
  color: var(--text-secondary);
  font-style: italic;
  text-align: center;
  padding: 1rem;
}

/* Keep other existing styles */

/* Settings Page Styles */
.settings-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.settings-group {
  margin-bottom: 2.5rem;
}

.settings-group:last-child {
  margin-bottom: 0;
}

.settings-group-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.settings-group-header h2 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
}

.settings-group-icon {
  width: 24px;
  height: 24px;
}

.settings-items {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.settings-item-card {
  background-color: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 1.25rem;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settings-item-card:hover {
  background-color: rgba(255, 255, 255, 0.05);
  transform: translateY(-1px);
}

.settings-item-content {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.settings-item-icon {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.settings-item-icon svg {
  width: 20px;
  height: 20px;
}

.settings-item-text {
  flex: 1;
}

.settings-item-text h3 {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 0.25rem;
}

.settings-item-text p {
  margin: 0;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.6);
}

.settings-item-arrow {
  width: 20px;
  height: 20px;
  color: rgba(255, 255, 255, 0.4);
}

/* Responsive Adjustments */
@media (max-width: 640px) {
  .settings-container {
    padding: 1rem;
  }

  .settings-item-card {
    padding: 1rem;
  }

  .settings-item-icon {
    width: 36px;
    height: 36px;
  }

  .settings-item-text h3 {
    font-size: 0.9375rem;
  }

  .settings-item-text p {
    font-size: 0.8125rem;
  }
}

/* Settings Page Styles */
.settings-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 1.5rem;
  padding: 1.5rem;
}

.settings-card {
  background-color: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  overflow: hidden;
}

.settings-card-header {
  padding: 1.25rem 1.5rem;
  background-color: rgba(255, 255, 255, 0.02);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.settings-card-header h3 {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.9);
}

.settings-icon {
  width: 20px;
  height: 20px;
  color: #40c057;
}

.settings-card-content {
  padding: 1.5rem;
}

.setting-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;
}

.setting-item:last-child {
  margin-bottom: 0;
}

.setting-item label {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.875rem;
}

.setting-item input[type="text"],
.setting-item input[type="email"],
.setting-item input[type="number"],
.setting-item select {
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  padding: 0.5rem 0.75rem;
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.875rem;
  width: 200px;
}

.setting-item input[type="number"] {
  width: 100px;
}

.setting-item select {
  cursor: pointer;
}

/* Toggle Switch */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.1);
  transition: .4s;
  border-radius: 24px;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

.toggle-switch input:checked + .toggle-slider {
  background-color: #40c057;
}

.toggle-switch input:checked + .toggle-slider:before {
  transform: translateX(20px);
}

/* Save Button */
.save-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.save-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.save-button:hover:not(:disabled) {
  transform: translateY(-1px);
  filter: brightness(1.1);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .settings-grid {
    grid-template-columns: 1fr;
  }

  .setting-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .setting-item input[type="text"],
  .setting-item input[type="email"],
  .setting-item input[type="number"],
  .setting-item select {
    width: 100%;
  }
}

/* Admin Dashboard Styles */
.quick-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
  padding: 0 1.5rem;
}

.stat-card {
  background-color: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.stat-icon-wrapper {
  padding: 0.75rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stat-icon {
  width: 24px;
  height: 24px;
}

.stat-content {
  flex: 1;
}

.stat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.stat-label {
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.6);
}

.stat-trend {
  font-size: 0.875rem;
  font-weight: 500;
}

.stat-value {
  font-size: 1.5rem;
  font-weight: 600;
}

.admin-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  padding: 1.5rem;
}

.admin-card {
  background-color: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.admin-card:hover {
  transform: translateY(-2px);
  background-color: rgba(255, 255, 255, 0.05);
}

.admin-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.admin-icon-wrapper {
  width: 48px;
  height: 48px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-icon-wrapper svg {
  width: 24px;
  height: 24px;
}

.admin-stats {
  text-align: right;
}

.admin-stats .stat-value {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.admin-stats .stat-label {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.6);
}

.admin-card-content h3 {
  margin: 0;
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.admin-card-content p {
  margin: 0;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.6);
  line-height: 1.5;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .quick-stats {
    grid-template-columns: 1fr;
  }

  .admin-grid {
    grid-template-columns: 1fr;
  }
}

/* Admin Page Styles */
.page-header {
  padding: 2rem 1.5rem 1rem;
}

.header-description {
  color: rgba(255, 255, 255, 0.6);
  margin-top: 0.5rem;
  font-size: 0.9375rem;
}

.admin-modules-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  padding: 1.5rem;
}

.admin-module-card {
  background-color: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.admin-module-card:hover {
  transform: translateY(-2px);
  background-color: rgba(255, 255, 255, 0.05);
}

.module-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}

.module-icon-wrapper {
  width: 48px;
  height: 48px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.module-icon-wrapper svg {
  width: 24px;
  height: 24px;
}

.module-stats {
  text-align: right;
}

.module-stats .stat-value {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.module-stats .stat-label {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.6);
}

.module-content h3 {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 0.25rem;
}

.module-content p {
  margin: 0;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.6);
  line-height: 1.5;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .admin-modules-grid {
    grid-template-columns: 1fr;
  }
  
  .page-header {
    padding: 1.5rem 1rem 0.5rem;
  }
}

/* User Management Specific Styles */
.users-container {
  padding: 1.5rem;
}

.controls-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  gap: 1rem;
}

.search-filters {
  display: flex;
  gap: 1rem;
  flex: 1;
}

.search-box {
  position: relative;
  flex: 1;
  max-width: 400px;
}

.search-box input {
  width: 100%;
  padding: 0.75rem 1rem 0.75rem 2.5rem;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.03);
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.875rem;
}

.search-box .search-icon {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  color: rgba(255, 255, 255, 0.4);
}

.filters {
  display: flex;
  gap: 0.75rem;
}

.filter-group {
  position: relative;
  display: flex;
  align-items: center;
}

.filter-group select {
  padding: 0.75rem 2.5rem 0.75rem 1rem;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.03);
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.875rem;
  appearance: none;
  cursor: pointer;
  min-width: 140px;
}

.filter-icon {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  color: rgba(255, 255, 255, 0.4);
}

.user-info {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.user-avatar {
  width: 36px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1rem;
}

.user-details {
  display: flex;
  flex-direction: column;
}

.user-name {
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  font-size: 0.875rem;
}

.user-email {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.75rem;
}

.badge {
  display: inline-flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  border-radius: 6px;
  font-size: 0.75rem;
  font-weight: 500;
  gap: 0.375rem;
}

.status-dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.action-buttons {
  display: flex;
  gap: 0.5rem;
}

.icon-button {
  padding: 0.375rem 0.75rem;
  border-radius: 6px;
  font-size: 0.75rem;
  font-weight: 500;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
  transition: all 0.2s ease;
}

.icon-button:hover {
  background: rgba(255, 255, 255, 0.06);
  transform: translateY(-1px);
}

.primary-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  background: #228be6;
  color: white;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

.primary-button:hover {
  background: #1c7ed6;
  transform: translateY(-1px);
}

/* Table Styles */
.table-container {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
}

.data-table th {
  background: rgba(255, 255, 255, 0.02);
  padding: 1rem;
  text-align: left;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.875rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.data-table td {
  padding: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.875rem;
}

.data-table tr:last-child td {
  border-bottom: none;
}

.data-table tbody tr {
  transition: background-color 0.2s ease;
}

.data-table tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.02);
}

.timestamp-cell {
  color: rgba(255, 255, 255, 0.5) !important;
  font-size: 0.75rem !important;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .controls-row {
    flex-direction: column;
    align-items: stretch;
  }

  .search-filters {
    flex-direction: column;
  }

  .search-box {
    max-width: none;
  }

  .filters {
    flex-wrap: wrap;
  }

  .filter-group {
    flex: 1;
    min-width: 200px;
  }

  .filter-group select {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .table-container {
    margin: 0 -1.5rem;
    border-radius: 0;
    border-left: none;
    border-right: none;
  }
}

/* Add Resource Modal Specific Styles */
.add-resource-modal .modal-content {
  background-color: #1a1b1e;
  border-radius: 12px;
  padding: 2rem;
  width: 100%;
  max-width: 800px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);
}

.add-resource-modal .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.add-resource-modal .modal-header h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #228be6;
  font-weight: 600;
}

.add-resource-modal .modal-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
}

.add-resource-modal .modal-form-group {
  margin-bottom: 1rem;
}

.add-resource-modal .modal-form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.875rem;
  font-weight: 500;
}

.add-resource-modal .unit-id input { border-left: 4px solid #228be6; }
.add-resource-modal .type select { border-left: 4px solid #40c057; }
.add-resource-modal .status select { border-left: 4px solid #fab005; }
.add-resource-modal .crew-count input { border-left: 4px solid #be4bdb; }
.add-resource-modal .location input { border-left: 4px solid #15aabf; }
.add-resource-modal .current-task input { border-left: 4px solid #fd7e14; }

.add-resource-modal .modal-form-group input,
.add-resource-modal .modal-form-group select {
  width: 100%;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.03);
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.add-resource-modal .modal-form-group input:focus,
.add-resource-modal .modal-form-group select:focus {
  outline: none;
  background-color: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.2);
}

.add-resource-modal .modal-actions {
  grid-column: 1 / -1;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
  padding-top: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.add-resource-modal .modal-button {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.add-resource-modal .modal-button.cancel {
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
}

.add-resource-modal .modal-button.submit {
  background: linear-gradient(45deg, #228be6, #15aabf);
  border: none;
  color: white;
}

.add-resource-modal .modal-button.submit:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(34, 139, 230, 0.3);
}

@media (max-width: 768px) {
  .add-resource-modal .modal-form {
    grid-template-columns: 1fr;
  }
  
  .add-resource-modal .modal-content {
    margin: 1rem;
    padding: 1.5rem;
  }
}

/* View User Modal Specific Styles */
.user-view-modal .modal-container {
  background-color: #1a1b1e;
  border-radius: 12px;
  padding: 2rem;
  width: 100%;
  max-width: 600px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);
}

.user-view-modal .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.user-view-modal .user-view-header {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.user-view-modal .large-avatar {
  width: 64px;
  height: 64px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.5);
}

.user-view-modal h2 {
  margin: 0;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.25rem;
  font-weight: 600;
}

.user-view-modal .user-email {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.875rem;
}

.user-view-modal .user-info-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 2rem;
}

.user-view-modal .info-card {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.user-view-modal .info-icon {
  padding: 0.75rem;
  border-radius: 8px;
  display: flex;
}

.user-view-modal .info-text {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.user-view-modal .info-text label {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.75rem;
}

.user-view-modal .info-text span {
  font-weight: 500;
}

.user-view-modal .permissions-section {
  margin-top: 2rem;
}

.user-view-modal .permissions-section h3 {
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 1rem;
  font-size: 1rem;
}

.user-view-modal .permissions-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

.user-view-modal .permission-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: rgba(255, 255, 255, 0.7);
}

.user-view-modal .modal-actions {
  margin-top: 2rem;
  padding-top: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: flex-end;
}

.user-view-modal .cancel-btn {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.user-view-modal .cancel-btn:hover {
  background: rgba(255, 255, 255, 0.08);
}

/* Create/Edit User Modal Specific Styles */
.user-form-modal .modal-container {
  background-color: #1a1b1e;
  border-radius: 12px;
  padding: 2rem;
  width: 100%;
  max-width: 600px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);
}

.user-form-modal .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.user-form-modal .modal-header h2 {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
}

.user-form-modal .form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.user-form-modal .form-group {
  margin-bottom: 1.5rem;
}

.user-form-modal .form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
  font-weight: 500;
}

.user-form-modal .form-group input,
.user-form-modal .form-group select {
  width: 100%;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.03);
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.user-form-modal .form-group input:focus,
.user-form-modal .form-group select:focus {
  outline: none;
  border-color: #228be6;
  box-shadow: 0 0 0 1px #228be6;
}

.user-form-modal .role-capabilities {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.user-form-modal .role-capabilities label {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.75rem;
  margin-bottom: 0.75rem;
}

.user-form-modal .role-capabilities ul {
  list-style: none;
  padding: 0;
  margin: 0;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
}

.user-form-modal .role-capabilities li {
  margin-bottom: 0.5rem;
  padding-left: 1.25rem;
  position: relative;
}

.user-form-modal .role-capabilities li:before {
  content: "•";
  position: absolute;
  left: 0;
  color: #228be6;
}

.user-form-modal .modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
  padding-top: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.user-form-modal .cancel-btn {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.user-form-modal .submit-btn {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  background: #228be6 !important;
  border: none !important;
  color: white !important;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.user-form-modal .submit-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(34, 139, 230, 0.3);
}

@media (max-width: 768px) {
  .user-form-modal .form-row {
    grid-template-columns: 1fr;
  }
}

/* Add to your existing System List Manager styles */
.list-table-container {
  margin-top: 1rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
}

.list-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.875rem;
}

.list-table th {
  text-align: left;
  padding: 0.75rem 1rem;
  background: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
}

.list-table td {
  padding: 0.75rem 1rem;
  color: rgba(255, 255, 255, 0.9);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.table-actions {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
}

.icon-btn.small {
  padding: 0.25rem;
}

.icon-btn.small .btn-icon {
  width: 1rem;
  height: 1rem;
}

.add-item-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  border: none;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.add-item-btn:hover {
  background: rgba(255, 255, 255, 0.1);
}

.list-item {
  max-width: none !important;
  grid-column: span 2;
}

.items-grid {
  grid-template-columns: repeat(2, 1fr) !important;
}

@media (max-width: 1200px) {
  .list-item {
    grid-column: span 1;
  }
  
  .items-grid {
    grid-template-columns: 1fr !important;
  }
}

/* Update these styles in your existing System List Manager styles */

.icon-btn {
  padding: 0.5rem;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

.icon-btn:hover {
  transform: translateY(-1px);
  filter: brightness(1.1);
}

.add-item-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  width: 100%;
  border: none;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.add-item-btn:hover {
  filter: brightness(1.1);
  transform: translateY(-1px);
}

.btn-icon {
  width: 1.25rem;
  height: 1.25rem;
}

/* Add to your existing System List Manager styles */
.table-header {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.table-header .list-table {
  flex: 1;
}

.table-header-actions {
  padding-top: 0.75rem; /* Align with table header */
}

.list-table th:last-child,
.list-table td:last-child {
  text-align: center;
}

/* Add to your existing styles */
.inline-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.875rem;
}

.inline-input:focus {
  outline: none;
  border-color: #228be6;
  box-shadow: 0 0 0 1px #228be6;
}

.new-item-row {
  background: rgba(255, 255, 255, 0.02);
}

.new-item-row td {
  padding: 0.5rem 1rem;
}

/* Audit Log Styles */
.audit-log-page {
  padding: 2rem;
}

.audit-log-page .page-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.audit-log-page .header-content h1 {
  color: rgba(255, 255, 255, 0.9);
  margin: 0 0 0.5rem 0;
}

.audit-log-page .header-description {
  color: rgba(255, 255, 255, 0.6);
  margin: 0;
}

.audit-log-page .header-actions {
  display: flex;
  gap: 1rem;
}

.audit-log-page .filter-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.audit-log-page .filter-btn:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-1px);
}

.audit-table-container {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  overflow: hidden;
}

.audit-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.875rem;
}

.audit-table th {
  text-align: left;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
}

.audit-table td {
  padding: 1rem;
  color: rgba(255, 255, 255, 0.9);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.audit-table .timestamp {
  color: rgba(255, 255, 255, 0.5);
  font-family: monospace;
}

.audit-table .type-badge {
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 500;
}

.audit-table tr:hover {
  background: rgba(255, 255, 255, 0.02);
}

/* Add these styles for the Audit Log page back button */
.audit-log-page .back-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
  text-decoration: none;
  transition: all 0.2s ease;
}

.audit-log-page .back-btn:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-1px);
}

.audit-log-page .back-btn .btn-icon {
  width: 1.25rem;
  height: 1.25rem;
}

/* Update the System List page back button styles */
.system-list-page .back-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
  text-decoration: none;
  transition: all 0.2s ease;
}

.system-list-page .back-btn:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-1px);
}

.system-list-page .back-btn .btn-icon {
  width: 1.25rem;
  height: 1.25rem;
}

/* Role Management Styles */
.role-management-page {
  padding: 2rem;
}

.role-management-page .page-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.role-management-page .header-content h1 {
  color: rgba(255, 255, 255, 0.9);
  margin: 0 0 0.5rem 0;
}

.role-management-page .header-description {
  color: rgba(255, 255, 255, 0.6);
  margin: 0;
}

.role-management-page .header-actions {
  display: flex;
  gap: 1rem;
}

.role-management-page .back-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
  text-decoration: none;
  transition: all 0.2s ease;
}

.role-management-page .back-btn:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-1px);
}

.role-management-page .create-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  background: #228be6;
  border: none;
  color: white;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.role-management-page .create-btn:hover {
  transform: translateY(-1px);
  filter: brightness(1.1);
}

.roles-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  gap: 1.5rem;
}

.role-card {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  overflow: hidden;
}

.role-header {
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.role-info h3 {
  color: rgba(255, 255, 255, 0.9);
  margin: 0 0 0.5rem 0;
}

.role-info p {
  color: rgba(255, 255, 255, 0.6);
  margin: 0;
  font-size: 0.875rem;
}

.role-actions {
  display: flex;
  gap: 0.5rem;
}

.role-actions .icon-btn {
  padding: 0.5rem;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

.role-actions .icon-btn:hover {
  transform: translateY(-1px);
  filter: brightness(1.1);
}

.permissions-table-container {
  padding: 1.5rem;
}

.permissions-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.875rem;
}

.permissions-table th {
  text-align: left;
  padding: 0.75rem;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  text-transform: capitalize;
}

.permissions-table td {
  padding: 0.75rem;
  color: rgba(255, 255, 255, 0.9);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.permission-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 auto;
}

.permission-indicator.active {
  background: #40c057;
}

.permission-indicator.inactive {
  background: rgba(255, 255, 255, 0.1);
}

/* Add to your existing Role Management styles */
.permission-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  margin: 0 auto;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 2px solid rgba(255, 255, 255, 0.1);
}

.permission-checkbox.active {
  background: #40c057;
  border-color: #40c057;
}

.permission-checkbox:hover {
  transform: scale(1.1);
}

.edit-actions {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.save-btn, .cancel-btn {
  padding: 0.75rem 1rem;
  border-radius: 6px;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.save-btn {
  background: #228be6;
  color: white;
  border: none;
}

.cancel-btn {
  background: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.save-btn:hover, .cancel-btn:hover {
  transform: translateY(-1px);
}

.save-btn:hover {
  filter: brightness(1.1);
}

.cancel-btn:hover {
  background: rgba(255, 255, 255, 0.1);
}

/* Add to your existing Role Management styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #1a1b1e;
  border-radius: 12px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-header {
  padding: 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h2 {
  color: rgba(255, 255, 255, 0.9);
  margin: 0;
}

.close-btn {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.close-btn:hover {
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.9);
}

.modal-body {
  padding: 1.5rem;
}

.modal-footer {
  padding: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
}

.form-input {
  width: 100%;
  padding: 0.75rem;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.form-input:focus {
  outline: none;
  border-color: #228be6;
  box-shadow: 0 0 0 1px #228be6;
}

.modal-body .permissions-table {
  margin-top: 0.5rem;
}

/* Settings Page Styles */
.settings-page {
  padding: 2rem;
}

.settings-page .page-header {
  margin-bottom: 2rem;
}

.settings-page .header-content h1 {
  color: rgba(255, 255, 255, 0.9);
  margin: 0 0 0.5rem 0;
}

.settings-page .header-description {
  color: rgba(255, 255, 255, 0.6);
  margin: 0;
}

.settings-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 1.5rem;
}

.settings-section {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  overflow: hidden;
}

.section-header {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.section-header h2 {
  margin: 0;
  font-size: 1.25rem;
}

.section-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.section-content {
  padding: 1rem;
}

.setting-item {
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.setting-item:hover {
  background: rgba(255, 255, 255, 0.05);
}

.setting-info h3 {
  color: rgba(255, 255, 255, 0.9);
  margin: 0 0 0.25rem 0;
  font-size: 1rem;
}

.setting-info p {
  color: rgba(255, 255, 255, 0.6);
  margin: 0;
  font-size: 0.875rem;
}

.setting-btn {
  background: none;
  border: none;
  padding: 0.5rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.setting-btn:hover {
  color: rgba(255, 255, 255, 0.9);
}