.communications-container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.page-title {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #fff;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.stat-card {
  background: rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  border-radius: 12px;
  backdrop-filter: blur(10px);
  transition: transform 0.2s;
}

.stat-card:hover {
  transform: translateY(-2px);
}

.stat-card h2 {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  color: #fff;
}

.stat-number {
  font-size: 2.5rem;
  font-weight: bold;
}

.stat-number.alert { color: #ff4444; }
.stat-number.warning { color: #ffa000; }
.stat-number.info { color: #2196f3; }

.action-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
}

.action-card {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  border-radius: 12px;
  text-decoration: none;
  transition: transform 0.2s, background-color 0.2s;
}

.action-card:hover {
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 0.15);
}

.card-icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.card-icon.alert { background: rgba(255, 68, 68, 0.2); color: #ff4444; }
.card-icon.warning { background: rgba(255, 160, 0, 0.2); color: #ffa000; }
.card-icon.info { background: rgba(33, 150, 243, 0.2); color: #2196f3; }

.card-content {
  flex: 1;
}

.card-content h3 {
  color: #fff;
  margin: 0 0 0.5rem 0;
  font-size: 1.2rem;
}

.card-content p {
  color: #aaa;
  margin: 0;
  font-size: 0.9rem;
}

.table-section {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.table-header h2 {
  color: #fff;
  margin: 0;
  font-size: 1.2rem;
}

.action-button {
  background: rgba(33, 150, 243, 0.2);
  color: #2196f3;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: background-color 0.2s;
}

.action-button:hover {
  background: rgba(33, 150, 243, 0.3);
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 0.5rem;
}

.data-table th,
.data-table td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.data-table th {
  color: #aaa;
  font-weight: 500;
  font-size: 0.9rem;
}

.data-table td {
  color: #fff;
}

.table-action-btn {
  background: none;
  border: none;
  color: #aaa;
  padding: 0.3rem 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
  transition: color 0.2s;
}

.table-action-btn:hover {
  color: #fff;
}

.priority {
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
  font-size: 0.85rem;
  font-weight: 500;
}

.priority.high {
  background: rgba(255, 68, 68, 0.2);
  color: #ff4444;
}

.priority.medium {
  background: rgba(255, 160, 0, 0.2);
  color: #ffa000;
}

.priority.low {
  background: rgba(76, 175, 80, 0.2);
  color: #4caf50;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .data-table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
  
  .table-header {
    flex-direction: column;
    gap: 1rem;
  }
  
  .action-button {
    width: 100%;
    justify-content: center;
  }
} 

.action-buttons {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

.action-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  text-decoration: none;
  transition: transform 0.2s, opacity 0.2s;
}

.action-button:hover {
  transform: translateY(-2px);
  opacity: 0.9;
}

.action-button.emergency {
  background: #ff4444;
  color: white;
}

.action-button.announcement {
  background: #ffa000;
  color: white;
}

.type-badge {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.4rem 0.8rem;
  border-radius: 6px;
  font-size: 0.85rem;
  font-weight: 500;
}

.type-badge.alert {
  background: rgba(255, 68, 68, 0.2);
  color: #ff4444;
}

.type-badge.announcement {
  background: rgba(255, 160, 0, 0.2);
  color: #ffa000;
}

.type-badge i {
  font-size: 0.9rem;
}

tr.alert {
  background: rgba(255, 68, 68, 0.05);
}

tr.announcement {
  background: rgba(255, 160, 0, 0.05);
}

.priority {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
  font-size: 0.85rem;
  font-weight: 500;
}

.priority.high {
  background: rgba(255, 68, 68, 0.2);
  color: #ff4444;
}

.priority.normal {
  background: rgba(76, 175, 80, 0.2);
  color: #4caf50;
}

.priority.low {
  background: rgba(33, 150, 243, 0.2);
  color: #2196f3;
}

.data-table tr:hover {
  background: rgba(255, 255, 255, 0.05);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .action-buttons {
    flex-direction: column;
  }

  .action-button {
    width: 100%;
    justify-content: center;
  }
} 