.announcements-container {
  padding: 2rem;
  color: #fff;
  max-width: 800px;
  margin: 0 auto;
}

.announcements-header {
  margin-bottom: 2rem;
}

.announcements-back-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: none;
  border: none;
  color: #aaa;
  cursor: pointer;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

.announcements-back-button:hover {
  color: #fff;
}

.announcements-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.announcements-title {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.announcements-icon {
  width: 2rem;
  height: 2rem;
  color: #ffa000;
}

.announcements-form {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 2rem;
}

.form-section {
  margin-bottom: 2rem;
}

.form-section h2 {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  color: #fff;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #fff;
}

.form-group input[type="text"],
.form-group input[type="datetime-local"],
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 1rem;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #ffa000;
}

.checkbox-group {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.checkbox-group label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #fff;
  cursor: pointer;
}

.checkbox-group input[type="checkbox"] {
  width: 16px;
  height: 16px;
  accent-color: #ffa000;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.cancel-button {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cancel-button:hover {
  background: rgba(255, 255, 255, 0.15);
}

.submit-button {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  background: #ffa000;
  border: none;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submit-button:hover {
  background: #ff9100;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .announcements-container {
    padding: 1rem;
  }
} 