.lists-container {
  display: grid;
  gap: 24px;
  padding: 20px;
}

.detail-card {
  background: #1e1e1e;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.2);
  padding: 20px;
  color: #ffffff;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #333;
  padding-bottom: 16px;
}

.card-header h2 {
  color: #ffffff;
}

.add-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: #2c2c2c;
  color: #fff;
  border: 1px solid #444;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s;
}

.add-button:hover {
  background: #3c3c3c;
  transform: translateY(-1px);
}

.list-table {
  width: 100%;
  border-collapse: collapse;
  background: #2c2c2c;
  border-radius: 8px;
  overflow: hidden;
}

.list-table th {
  text-align: left;
  padding: 12px 16px;
  background: #333;
  color: #fff;
  font-weight: 500;
  border-bottom: 2px solid #444;
}

.list-table td {
  padding: 8px 16px;
  border-bottom: 1px solid #444;
  color: #fff;
}

.list-table tr:hover {
  background: #363636;
}

.color-indicator {
  width: 20px;
  height: 20px;
  border-radius: 6px;
  border: 2px solid rgba(255,255,255,0.1);
  margin: 0 auto;
}

.action-buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
}

.edit-button, .delete-button, .save-button, .cancel-button {
  padding: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.edit-button {
  background: #0066cc;
  color: white;
}

.delete-button {
  background: #cc3333;
  color: white;
}

.save-button {
  background: #2ea043;
  color: white;
}

.cancel-button {
  background: #666;
  color: white;
}

.edit-button:hover, .save-button:hover {
  transform: translateY(-1px);
  filter: brightness(1.2);
}

.delete-button:hover {
  background: #b32d2d;
  transform: translateY(-1px);
}

.cancel-button:hover {
  background: #555;
  transform: translateY(-1px);
}

.icon {
  width: 14px;
  height: 14px;
}

.edit-input {
  padding: 8px;
  background: #333;
  border: 2px solid #0066cc;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  color: white;
}

.edit-input:focus {
  outline: none;
  border-color: #0077ee;
  background: #3c3c3c;
}

/* Add smooth transitions */
.list-table tr, .detail-card, .edit-input, .action-buttons button {
  transition: all 0.2s ease;
} 

.list-row {
  height: 40px;
}

.new-row {
  height: 40px;
  background: #2c2c2c;
}

.add-inline-button {
  background: none;
  border: none;
  color: #666;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  transition: all 0.2s;
  margin: 0 auto;
  justify-content: center;
}

.add-inline-button:hover {
  color: #fff;
  background: #363636;
}

.color-indicator {
  width: 20px;
  height: 20px;
}

.action-buttons button {
  padding: 4px;
}

.icon {
  width: 14px;
  height: 14px;
}

.list-table th:nth-child(2),
.list-table th:nth-child(3) {
  text-align: center;
}

.list-table td:nth-child(2),
.list-table td:nth-child(3) {
  text-align: center;
}

.new-row td:nth-child(2),
.new-row td:nth-child(3) {
  text-align: center;
}

.color-picker-container {
  position: relative;
  display: flex;
  justify-content: center;
}

.color-indicator {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 auto;
  transition: transform 0.2s;
}

.color-indicator:hover {
  transform: scale(1.1);
}

.color-picker-popover {
  position: absolute;
  z-index: 1000;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  background: #1e1e1e;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.3);
}

.color-picker-cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
}

.color-chips {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  position: relative;
  z-index: 1001;
}

.color-chip {
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 0;
  transition: transform 0.2s;
  border: 2px solid rgba(255,255,255,0.1);
}

.color-chip:hover {
  transform: scale(1.1);
}

.color-indicator {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
  border: 2px solid rgba(255,255,255,0.1);
}

.rcp {
  background: #1e1e1e !important;
  border-radius: 8px;
}

/* Style for new items */
.new-row .color-indicator {
  background-color: #ced4da;
  opacity: 0.5;
}

/* Add a subtle pulse animation to indicate clickable */
@keyframes pulse {
  0% { box-shadow: 0 0 0 0 rgba(255,255,255,0.1); }
  70% { box-shadow: 0 0 0 4px rgba(255,255,255,0); }
  100% { box-shadow: 0 0 0 0 rgba(255,255,255,0); }
}

.color-indicator:hover {
  animation: pulse 1.5s infinite;
}
  