.emergency-alerts-container {
  padding: 2rem;
  color: #fff;
  max-width: 800px;
  margin: 0 auto;
}

.alerts-header {
  margin-bottom: 2rem;
}

.alerts-back-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: none;
  border: none;
  color: #aaa;
  cursor: pointer;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

.alerts-back-button:hover {
  color: #fff;
}

.alerts-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alerts-title {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.alerts-icon {
  width: 2rem;
  height: 2rem;
  color: #ff4444;
}

.alerts-form {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 2rem;
}

.form-section {
  margin-bottom: 2rem;
}

.form-section h2 {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  color: #fff;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #fff;
}

.form-group input[type="text"],
.form-group input[type="datetime-local"],
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  font-size: 1rem;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #ff4444;
}

.form-group select option {
  background: #2a2a2a;
  color: #fff;
}

.checkbox-group {
  display: grid;
  gap: 1rem;
}

.checkbox-group label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.checkbox-group input[type="checkbox"] {
  width: 1rem;
  height: 1rem;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.cancel-button {
  background: none;
  border: none;
  color: #aaa;
  cursor: pointer;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  transition: all 0.2s;
}

.cancel-button:hover {
  background: rgba(255, 255, 255, 0.05);
}

.submit-button {
  background: rgba(255, 68, 68, 0.1);
  color: #ff4444;
  border: 1px solid #ff4444;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
}

.submit-button:hover {
  background: rgba(255, 68, 68, 0.2);
} 